<template>
  <div>
    <!--begin::Card-->

    <!--end::Card-->

    <!--begin::Row-->
    <div class="row" v-if="false">
      <div class="col-lg-6">
        <main-dashboard-details></main-dashboard-details>
      </div>
      <div class="col-lg-6">
        <redeem-token></redeem-token>
      </div>
    </div>
    <!--end::Row-->
    <div class="row">
      <div class="col-lg-12">
        <free-content-dashboard-handler></free-content-dashboard-handler>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <token-redeemed-dashboard></token-redeemed-dashboard>
      </div>
    </div>
    <!--begin::Row-->
    <div class="row">
      <div class="col-lg-12">
        <attendee-tokens-grid></attendee-tokens-grid>
      </div>
    </div>
    <!--end::Row-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MainDashboardDetails from "@/view/pages/main-dashboard/components/MainDashboardDetails/MainDashboardDetails";
import RedeemToken from "@/view/pages/tokens/RedeemToken/RedeemToken";
import AttendeeTokensGrid from "@/view/pages/tokens/AttendeeTokensGrid/AttendeeTokensGrid";
import FreeContentDashboardHandler from "@/view/pages/free-contents/FreeContentDashboard/FreeContentDashboard";
import TokenRedeemedDashboard from "@/view/pages/tokens/TokenRedeemedDashboard/TokenRedeemedDashboard";

export default {
  name: "MainDashboard",
  components: {
    TokenRedeemedDashboard,
    FreeContentDashboardHandler,
    AttendeeTokensGrid,
    RedeemToken,
    MainDashboardDetails,
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", currentUserPicture: "auth/currentUserPicture" }),
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("GENERAL.DASHBOARD") }]);
  },
};
</script>
