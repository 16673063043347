<template>
  <div class="FreeContentDashboard">
    <transition name="fade-in-up">
      <div
        v-if="freeContentToken !== ''"
        class="card card-custom bgi-no-repeat mb-10"
        style="background-color: #c3f5c9; background-position: 100% 30%; background-size: 30% auto"
        :style="{
          backgroundImage: `url(${backgroundImage('media/svg/illustrations/features.svg')})`,
        }"
      >
        <!--begin::Body-->
        <div class="card-body d-flex align-items-center">
          <div>
            <h3
              class="text-dark font-weight-bolder line-height-lg mb-5 rounded mr-10"
              style="background-color: rgba(192, 245, 201, 0.8)"
              v-html="$t('GENERAL.FREE_CONTENT_RESULT')"
            ></h3>

            <h3 class="my-5">
              <div class="lead badge badge-info">
                {{ $t("GENERAL.TOKEN") }}:
                {{ currentFreeContentToken }}
              </div>
            </h3>
            <router-link
              :to="{
                name: 'content-run',
                params: { token: currentFreeContentToken },
              }"
              class="btn btn-success btn-lg font-weight-bold p-5 lead font-weight-bolder px-10"
            >
              <i class="fa fa-eye"></i>
              {{ $t("GENERAL.ACCESS") }}
            </router-link>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </transition>
  </div>
</template>

<script>
import LAUtils from "@/core/services/LAUtils.service";
import { mapGetters } from "vuex";

export default {
  name: "free-content-dashboard-handler",
  computed: {
    ...mapGetters({ freeContentToken: "freeContent/freeContentToken" }),
    currentFreeContentToken() {
      return this.freeContentToken || "";
    },
  },
  methods: {
    backgroundImage(url) {
      return LAUtils.backgroundImage(url);
    },
  },
  mounted() {},
};
</script>
