<template>
  <div class="TokenRedeemedDashboard">
    <transition name="fade-in-up">
      <div
        v-if="lastRedeemedTokenValue !== ''"
        class="card card-custom bgi-no-repeat mb-10"
        style="
          background-color: #c3f5c9;
          background-position: 100% 30%;

          background-size: 30% auto;
        "
        :style="{
          backgroundImage: `url(${backgroundImage('media/svg/illustrations/login-visual-5.svg')})`,
        }"
      >
        <!--begin::Body-->
        <div class="card-body d-flex align-items-center">
          <div class="w-100">
            <h2
              class="text-dark font-weight-bolder line-height-lg mb-5 rounded mr-10"
              style="background-color: rgba(192, 245, 201, 0.8)"
              v-html="$t('GENERAL.REDEEMED_TOKEN_GREETINGS2')"
            ></h2>
            <div class="">
              <h5 class="my-5">
                <div class="badge badge-light">
                  {{ $t("GENERAL.TOKEN") }}:
                  {{ lastRedeemedTokenValue }}
                </div>
              </h5>
              <router-link
                :to="{
                  name: 'content-run',
                  params: { token: lastRedeemedTokenValue },
                }"
                class="btn btn-success btn-lg font-weight-bold p-5 lead font-weight-bolder px-10"
              >
                <i class="fa fa-play"></i>
                {{ $t("GENERAL.ACCESS") }}
              </router-link>
            </div>
          </div>
        </div>

        <!--end::Body-->
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LAUtils from "@/core/services/LAUtils.service";

export default {
  name: "token-redeemed-dashboard",
  computed: {
    ...mapGetters({ lastRedeemedToken: "token/lastRedeemedToken" }),
    lastRedeemedTokenValue() {
      return this.lastRedeemedToken || "";
    },
  },
  methods: {
    backgroundImage(url) {
      return LAUtils.backgroundImage(url);
    },
  },
  mounted() {},
};
</script>
