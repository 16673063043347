<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <!--begin::Details-->
      <div class="d-flex mb-9">
        <!--begin: Pic-->
        <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
          <div class="symbol symbol-50 symbol-lg-120 bordered">
            <img :src="currentUserPicture" alt="image" class="  " />
          </div>

          <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
            <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex mr-3">
              <a href="#" class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                >{{ $t("GENERAL.WELCOME") | uppercase }}, {{ currentUser.name | uppercase }}</a
              >
              <span v-if="currentUser.white_label_enabled"><i class="flaticon2-correct text-success font-size-h5"></i></span>
            </div>

            <div class="my-lg-0 my-3">
              <router-link :to="{ name: 'profile-main' }" class="btn btn-sm btn-light-success font-weight-bolder text-uppercase">
                <span class="svg-icon svg-icon-md svg-icon-success">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/General/User.svg" />
                  <!--end::Svg Icon--> </span
                >{{ $t("GENERAL.PROFILE") }}</router-link
              >
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Content-->
          <div class="flex-wrap justify-content-between mt-1">
            <div class="d-flex flex-column flex-grow-1 pr-8">
              <div class="d-flex flex-wrap mb-4">
                <a href="#" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-new-email mr-2 font-size-lg"></i>{{ currentUser.email }}</a
                >
              </div>

              <div class="font-weight-bold lead mb-6 mt-10">
                {{ $t("GENERAL.GENERAL_DATA") }}
              </div>
              <span class="font-weight-bold text-dark-50"> {{ $t("GENERAL.FOLLOW_PROGRESS") }}</span>
            </div>

            <div class="d-flex align-items-center w-100 flex-fill float-right mt-lg-12 mt-8">
              <span class="font-weight-bold text-dark-75">
                {{ $t("GENERAL.PROGRESS") }}
              </span>
              <div class="progress progress-xs mx-3 w-100">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  :style="{
                    width: progress + '%',
                  }"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="font-weight-bolder text-dark">{{ progress }}%</span>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <div class="separator separator-solid"></div>

      <!--begin::Items-->
      <div class="d-flex align-items-center flex-wrap mt-8">
        <!--begin::Item-->
        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
          <span class="mr-4">
            <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">
              {{ $t("GENERAL.TOKENS") }}
            </span>
            <span class="font-weight-bolder font-size-h5"><span class="text-dark-50 font-weight-bold"></span> {{ attendeeProgress.length }}</span>
          </div>
        </div>
        <!--end::Item-->

        <!--begin::Item-->
        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
          <span class="mr-4">
            <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm"> {{ $t("GENERAL.USED_TOKENS") }}</span>
            <span class="font-weight-bolder font-size-h5"><span class="text-dark-50 font-weight-bold"></span> {{ usedTokens.length }}</span>
          </div>
        </div>
        <!--end::Item-->

        <!--begin::Item-->
        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
          <span class="mr-4">
            <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
          </span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">
              {{ $t("GENERAL.FINISHED_TOKENS") }}
            </span>
            <span class="font-weight-bolder font-size-h5"> {{ finishedTokens.length }}</span>
          </div>
        </div>
        <!--end::Item-->
      </div>
      <!--begin::Items-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ATTENDEE_PROGRESS } from "@/core/services/store/token.module";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "main-dashboard-details",
  mixins: [uppercase],
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", currentUserPicture: "auth/currentUserPicture", attendeeProgress: "token/attendeeProgress" }),

    usedTokens: {
      get() {
        return this.attendeeProgress.filter((elem) => {
          return elem.token_progress > 0;
        });
      },
    },
    finishedTokens: {
      get() {
        return this.attendeeProgress.filter((elem) => {
          return elem.token_progress === 100;
        });
      },
    },
    progress: {
      get() {
        if (this.attendeeProgress.length === 0) {
          return 0;
        }
        return ((this.finishedTokens.length / this.attendeeProgress.length) * 100).toFixed(0);
      },
    },
  },
  mounted() {
    this.$store.dispatch("token/" + GET_ATTENDEE_PROGRESS);
  },
};
</script>
