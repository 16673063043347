<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h2 class="align-items-start flex-column font-weight-light mt-4">
        <span class="card-label font-weight-bolder text-dark mt-3">
          {{ $t("GENERAL.ATTENDEE_TOKENS") | uppercase }}
        </span>

        <span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="false"> </span>
      </h2>
      <div class="card-toolbar">
        <div class="">
          {{ $t("GENERAL.PROGRESS") }}:
          <select name="" class="mr-8 btn border" v-model="selectedProgress" @change="changeProgress">
            <option value="all">{{ $t("GENERAL.ALL") | uppercase }}</option>
            <option value="finished">{{ $t("GENERAL.FINISHED") | uppercase }}</option>
            <option value="started">{{ $t("GENERAL.STARTED") | uppercase }}</option>
            <option value="not_started">{{ $t("GENERAL.NOT_STARTED") | uppercase }}</option>
            <option value="available">{{ $t("GENERAL.AVAILABLE") | uppercase }}</option>
          </select>
          <button class="btn mr-4" :class="{ 'btn-primary': type === 'map', 'btn-outline-primary': type !== 'map' }" @click="getData(1, 'map')">
            {{ $tc("GENERAL.MAP", 2) | uppercase }}
          </button>
          <button class="btn mr-4" @click="getData(1, 'program')" :class="{ 'btn-primary': type === 'program', 'btn-outline-primary': type !== 'program' }">
            {{ $tc("GENERAL.PROGRAM", 2) | uppercase }}
          </button>
          <button class="btn" @click="getData(1, 'all')" :class="{ 'btn-primary': type === 'all', 'btn-outline-primary': type !== 'all' }">
            {{ $tc("GENERAL.ALL", 2) | uppercase }}
          </button>
        </div>
      </div>
    </div>
    <hr class="mb-6" />
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0 shadow-xs">
      <!--begin::Table-->
      <div class="blockquote" v-if="dataToShow.length === 0 && status !== 'loading'">
        {{ $t("GENERAL.NO_RECORDS_FOUND") }}
      </div>
      <div class="blockquote justify-content-center" v-if="status === 'loading'">
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="auto"><b-spinner variant="success"></b-spinner></b-col>
        </b-row>
      </div>

      <div class="table-responsive h-100" v-if="dataToShow.length > 0 && status !== 'loading'">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>

              <th class="p-0" style="min-width: 200px"></th>
              <th class="p-0" style="min-width: 40px"></th>

              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 150px"></th>
              <th class="p-0" style="min-width: 90px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in dataToShow">
              <tr v-bind:key="i" class="">
                <td class="pl-0 py-4 justify-content-center">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="">
                      <router-link
                        :to="{
                          name: 'content-run',
                          params: { token: item.token_token },
                        }"
                        class="btn btn-outline-light p-1"
                      >
                        <img :src="$laUtils.contentImagePath(item.content_photo_url)" class="align-self-center info" alt="" style="height: 60px" />
                      </router-link>
                    </span>
                  </div>
                </td>

                <td class="pl-0">
                  <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"> {{ item.texts.name }} </a>
                  <div v-if="false">
                    <span class="font-weight-bolder">Conteúdo:</span>
                  </div>
                </td>
                <td class="text-center pr-0">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    <router-link
                      v-if="item.token_progress !== 100"
                      :to="{
                        name: 'content-run',
                        params: { token: item.token_token },
                      }"
                      class="btn btn-icon btn-success btn-sm mb-2"
                    >
                      <i class="fa fa-play fa-sm"></i>
                    </router-link>
                    <router-link
                      :to="{
                        name: 'content-run',
                        params: { token: item.token_token },
                      }"
                      class="btn btn-icon btn-light-danger btn-sm font-weight-bolder mb-2"
                      v-else
                    >
                      <i class="fa fa-file-pdf"></i>
                    </router-link>
                  </span>
                  <span class="text-muted font-weight-bold mt-2">
                    <span v-if="item.token_progress === 100">
                      {{ $t("GENERAL.REPORT") }}
                    </span>
                    <span v-else-if="item.token_progress > 0 && item.token_progress < 100">
                      {{ $t("GENERAL.CONTINUE") }}
                    </span>
                    <span v-else>
                      {{ $t("GENERAL.START") }}
                    </span>
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    <b-badge pill variant="info" class="font-size-lg" v-if="item.token_token === lastRedeemedTokenValue">
                      <i class="fa fa-asterisk text-white font-size-sm"></i>
                      {{ $t("GENERAL.NEW") }}
                    </b-badge>

                    {{ item.token_token }}
                  </span>
                  <span class="text-muted font-weight-bold">
                    {{ item.purchase_user_white_label_name }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    <div class="progress" v-if="item.token_progress > 0">
                      <div
                        class="progress-bar success"
                        role="progressbar"
                        :style="{
                          width: item.token_progress + '%',
                        }"
                        :aria-valuenow="item.token_progress"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ item.token_progress }}%
                      </div>
                    </div>
                    <span v-if="item.token_progress > 0" class="text-left w-100 text-muted"> {{ item.token_progress }}% </span>
                    <span v-if="item.token_progress === 0 || item.token_progress == null" class="text-left w-100 text-muted">
                      {{ $t("GENERAL.NOT_STARTED") | uppercase }}
                      <span v-if="item.purchase_user_id === item.attendee_id">
                        <br />
                        <button class="btn btn-outline-success btn-sm mb-2" @click="showModal(item)">
                          <i class="fa fa-share-alt"></i> {{ $t("GENERAL.GIFT_FRIEND") }}
                        </button>
                      </span>
                    </span>
                  </span>
                </td>
                <td class="text-right">
                  <span class="label label-lg label-inline" :class="`label-light-${item.text6}`">
                    {{ $tc("GENERAL." + item.content_type.toUpperCase(), 1) }}
                  </span>
                  <span class="text-muted font-weight-500 d-block">
                    {{ item.token_redeem_date }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
    <div class="card-footer">
      <la-paginator v-if="dataToShow.length > 0" @page-click="(bvEvent, page) => getData(page)" :pagination="currentTokenPaginator"> </la-paginator>
    </div>
    <b-modal
      ref="my-modal"
      class="modal-lg"
      :ok-title="$t('GENERAL.SEND')"
      :cancel-title="$t('GENERAL.CANCEL')"
      :title="$t('GENERAL.GIFT_FRIEND')"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @ok="hideModal"
    >
      <div class="d-block" v-if="showingModal">
        <p class="text-danger">{{ $t("GENERAL.GIFT_WARNING") }}</p>

        <img :src="$laUtils.contentImagePath(selectedToken.content_photo_url)" class="align-self-center info" alt="" style="height: 60px" />
        <p>
          <strong> {{ $t("GENERAL.NAME") }}:</strong> {{ selectedToken.texts.name }}
        </p>
        <p>
          <strong>{{ $t("GENERAL.TOKEN") }}:</strong> {{ selectedToken.token_token }}
        </p>
        <p v-html="selectedToken.texts.description"></p>

        <b-form-group id="input-group-name" :label="$t('GENERAL.NAME')" label-for="input-name" :description="$t('GENERAL.WE_DONT_SHARE')">
          <b-form-input id="input-name" ref="input-name" type="text" v-model="giftName" required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-email" :label="$t('GENERAL.EMAIL')" label-for="input-email" :description="$t('GENERAL.WE_DONT_SHARE')">
          <b-form-input id="input-email" ref="input-email" type="email" v-model="giftEmail" required></b-form-input>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LaPaginator from "@/view/pages/components/LaPaginator/LaPaginator";
import { GET_ATTENDEE_TOKENS } from "@/core/services/store/token.module";
import ContentService from "@/core/services/content.service";
import uppercase from "@/core/mixins/uppercase.filter";
import { INVITE_ATTENDEE_FROM_CUSTOMER } from "@/core/services/store/admin-tokens.module";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "attendee-tokens-grid",
  mixins: [uppercase],
  data() {
    return {
      status: "loading",
      type: "all",
      page: 0,
      selectedToken: null,
      selectedProgress: "all",
      giftName: "",
      giftEmail: "",
    };
  },
  components: { LaPaginator },

  computed: {
    ...mapGetters({
      layoutConfig: "layoutConfig",
      currentTokens: "token/currentTokens",
      currentTokenPaginator: "token/currentTokenPaginator",
      lastRedeemedToken: "token/lastRedeemedToken",
    }),

    lastRedeemedTokenValue() {
      return this.lastRedeemedToken || "";
    },
    dataToShow() {
      return this.currentTokens || [];
    },
    showingModal() {
      return this.selectedToken !== null;
    },
  },
  methods: {
    showModal(token) {
      this.selectedToken = token;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$store
        .dispatch("adminTokens/" + INVITE_ATTENDEE_FROM_CUSTOMER, {
          email: this.$refs["input-email"].value,
          name: this.$refs["input-name"].value,
          token: this.selectedToken.token_token,
        })
        .then((response) => {
          this.selectedToken = null;
          this.$refs["my-modal"].hide();
          // TheToaster.success(this.$t("GENERAL.INVITE_SENT"));
          TheToaster.success(response.data.message, false);
          this.changeProgress();
        });
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    changeProgress() {
      let self = this;
      this.status = "loading";
      this.$store
        .dispatch("token/" + GET_ATTENDEE_TOKENS, { type: self.type, page: self.page, selectedProgress: self.selectedProgress })
        .then(() => {})
        .finally(() => {
          this.status = "loaded";
        });
    },
    getContentPhoto(photo_url) {
      return ContentService.getContentPhoto(photo_url);
    },
    getData(page, type = "") {
      if (type !== "") {
        this.type = type;
      }
      let self = this;
      self.page = page;
      this.status = "loading";
      this.$store
        .dispatch("token/" + GET_ATTENDEE_TOKENS, { type: self.type, page, selectedProgress: self.selectedProgress })
        .then(() => {})
        .finally(() => {
          this.status = "loaded";
        });
    },
  },

  mounted() {
    /*

"token_id": 2,
"token_token": "LAT-E8BFBDE8-5FF248BE",
"token_progress": 20,
"token_invite_email": "filiperp@gmail.com",
"token_redeem_date": "03/01/2021",
"attendee_id": 1,
"attendee_name": "Filipe",
"attendee_email": "filiperp@gmail.com",
"purchase_user_id": 1,
"purchase_user_name": "Filipe",
"purchase_user_white_label_name": "Colton Rodriguez",
"purchase_user_white_label_slug": "filipe",
"purchase_user_white_label_photo_url": null,
"content_id": 2,
"content_codename": "GRIP",
"content_photo_url": null,
"content_type": "map",
"content_engine": "grip",
"tag_name": "tag1"

*/
    setTimeout(() => this.getData(1), 600);
    return this.dataToShow;
  },
};
</script>
